<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiToolbox }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-2" elevation="1">
          <v-card-text>
            <v-divider class="mb-2"></v-divider>
            <v-text-field
              color="accent"
              class="mx-2"
              v-model="searchcannedcategory"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-data-table
              :headers="headerCategories"
              :items="cannedservice_categories"
              :items-per-page="5"
              :search="searchcannedcategory"
              sort-by="category_name"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(cannedCategory, index) in items" :key="index">
                    <td>
                      {{ cannedCategory.name }}
                    </td>
                    <td>
                      <v-menu offset-y bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn light icon v-bind="attrs" v-on="on">
                            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click.prevent="editCannedServiceCategory(cannedCategory)">
                            <v-list-item-icon>
                              <v-icon color="primary" class="mx-1">{{ icons.mdiPencil }}</v-icon>
                              <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item @click.prevent="deleteCannedServiceCategory(cannedCategory.cann_service_cat_id)">
                            <v-list-item-icon>
                              <v-icon color="error" class="mx-1"> {{ icons.mdiClose }}</v-icon>
                              <v-list-item-title>Remove</v-list-item-title>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:no-data>
                <v-btn color="accent" @click="getCannedSeviceCategories"> Reset </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <div v-for="(permission, index) in permission" :key="index">
            <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
              <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                <v-btn
                  class="ma-2"
                  v-show="user_submenu.create == 1 && permission.role_id == 3"
                  @click.prevent="addnewCannedServiceCategory"
                  rounded
                  color="primary"
                  ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                  >Create New
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mx-5">
            <v-btn
              v-show="currentUser.role_id == 1"
              color="accent"
              rounded
              @click.prevent="addnewCannedServiceCategory"
            >
              <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
              <span>Create New </span>
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-card elevation="1">
          <v-data-table
            :headers="headers"
            :items="cannedservices"
            :items-per-page="10"
            :search="searchcannedservices"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="page-title">
                  <v-icon class="secondary--text" left light>{{ icons.mdiWrenchOutline }}</v-icon>
                  Canned Items
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-layout>
                  <v-spacer></v-spacer>
                  <div v-for="(permission, index) in permission" :key="index">
                    <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                      <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                        <v-btn
                          v-show="user_submenu.create == 1 && permission.role_id == 3"
                          color="accent"
                          rounded
                          @click.prevent="$router.push({ name: 'addnewcannedservice' })"
                        >
                          <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                          <span>Create New </span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn
                      v-show="currentUser.role_id == 1"
                      color="accent"
                      rounded
                      @click.prevent="$router.push({ name: 'addnewcannedservice' })"
                    >
                      <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                      <span>Create New </span>
                    </v-btn>
                  </div>
                </v-layout>
              </v-toolbar>
              <v-divider class="mb-2"></v-divider>
              <v-text-field
                color="accent"
                class="mx-2"
                v-model="searchcannedservices"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(cannedService, index) in items" :key="index">
                  <td>
                    {{ cannedService.name }}
                  </td>
                  <td>{{ cannedService.items }}</td>
                  <td>
                    <v-layout class="mx-1">
                      <div v-for="(permission, index) in permission" :key="index">
                        <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                          <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                            <v-btn
                              v-show="user_submenu.update == 1 && permission.role_id == 3"
                              color="primary"
                              @click.prevent="
                                $router.push({
                                  name: 'editnewcannedservice',
                                  params: { id: cannedService.service_id },
                                })
                              "
                              fab
                              x-small
                              dark
                            >
                              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-show="currentUser.role_id == 1">
                        <v-btn
                          color="primary"
                          @click.prevent="
                            $router.push({
                              name: 'editnewcannedservice',
                              params: { id: cannedService.service_id },
                            })
                          "
                          fab
                          x-small
                          dark
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <div v-for="(permission, index) in permission" :key="index">
                          <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                            <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                              <v-btn
                                v-show="user_submenu.delete == 1 && permission.role_id == 3"
                                @click.prevent="deleteCannedService(cannedService.service_id)"
                                color="error"
                                fab
                                x-small
                                dark
                                class="mx-1"
                              >
                                <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            v-show="currentUser.role_id == 1"
                            @click.prevent="deleteCannedService(cannedService.service_id)"
                            color="error"
                            fab
                            x-small
                            dark
                            class="mx-1"
                          >
                            <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-layout>
                  </td>
                </tr>
              </tbody>
            </template>

            <template v-slot:no-data>
              <v-btn color="accent" @click="getCannedSevices"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <add-newcannedservices
      :dialog.sync="showNewCannedServiceCategoryDialog"
      @open-dialog="showNewCannedServiceCategoryDialog = true"
      @close-dialog="showNewCannedServiceCategoryDialog = false"
    />

    <editcannedservices
      :dialog.sync="showEditCannedServiceCategoryDialog"
      :cannedServiceCategory="cannedServiceCategory"
      @open-dialog="showEditCannedServiceCategoryDialog = true"
      @close-dialog="showEditCannedServiceCategoryDialog = false"
    />
  </v-card>
</template>

<script>
import AddNewDialog from '@/views/cannedservice/AddNewCannedServiceCategory.vue'
import EditNewDialog from '@/views/cannedservice/EditCannedServiceCategory.vue'
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiToolbox,
  mdiPencil,
  mdiClose,
  mdiWrenchOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    'add-newcannedservices': AddNewDialog,
    editcannedservices: EditNewDialog,
  },

  created: function () {
    this.getCannedSeviceCategories()
    this.getCannedSevices()
    this.getAuthorizations()
    window.mitt.on('loadCannedCategories', () => {
      this.getCannedSeviceCategories()
      this.getCannedSevices()
      this.getAuthorizations()
    })
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Items', value: 'items', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerCategories: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      cannedservices: [],
      permission: {},
      cannedservice_categories: [],
      showNewCannedServiceCategoryDialog: false,
      showEditCannedServiceCategoryDialog: false,
      searchcannedcategory: '',
      searchcannedservices: '',
      cannedServiceCategory: {
        name: '',
      },
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiToolbox,
        mdiPencil,
        mdiClose,
        mdiWrenchOutline,
      },
    }
  },
  methods: {
    getCannedSeviceCategories: function () {
      this.$store
        .dispatch('cannedService/fetchCannedServicesCategories')
        .then(response => {
          this.cannedservice_categories = this.fetchCannedServicesCategories
        })
        .catch(err => {
          console.log(err)
        })
    },

    getCannedSevices: function () {
      this.$store
        .dispatch('cannedService/fetchCannedServices', {
          canned_id: this.canned_id,
        })
        .then(response => {
          this.cannedservices = this.fetchCannedServices
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 7,
          submenu_id: 9,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteCannedServiceCategory(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`cannedService/deleteCannedServiceCategories`, {
                cann_service_cat_id: id,
              })
              .then(response => {
                this.getCannedSeviceCategories()
              })
          }
        })
    },

    deleteCannedService(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`cannedService/deleteCannedService`, {
                service_id: id,
              })
              .then(response => {
                this.getCannedSevices()
              })
          }
        })
    },

    addnewCannedServiceCategory: function () {
      this.showNewCannedServiceCategoryDialog = true
    },

    editCannedServiceCategory: function (cannedServiceCategory) {
      this.cannedServiceCategory = Object.assign({}, cannedServiceCategory)
      this.showEditCannedServiceCategoryDialog = true
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },
  computed: {
    ...mapGetters({
      fetchCannedServices: 'cannedService/fetchCannedServices',
      fetchCannedServicesCategories: 'cannedService/fetchCannedServicesCategories',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style>
</style>